<template>
	<div>
		<Header />
		<!-- <Nav /> -->
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
import Header from '../layouts/Header'
import Nav from '../layouts/Nav'
import Footer from '../layouts/Footer'
import '@/css/element-ui-variables.scss'


export default {
	components: {
		Header, Nav, Footer
	},
	data () {
		return {}
	},
	computed: {},
	created () {},
	mounted () {},
	methods: {}
}
</script>

<style scoped>
@import '~@/css/pc.css';
</style>
