<template>
	<div class="nav">
		<ul class="wrapper nav-list">
			<li 
				class="nav-item" 
				:class="{'nav-item-active': item.route_name === currentNav}"
				@click="jumpPage" 
				:data-page="item.route_name" 
				v-for="(item, index) in navs" :key="index"
			>{{ item.title }}</li>
		</ul>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
	components: {},
	data () {
		return {
			navs: [
				{
					title: "首页",
					route_name: "PcHome"
				},
				{
					title: "超级商城",
					route_name: "SuperMall"
				},
				{
					title: "发现好物",
					route_name: "Descover"
				},
				{
					title: "种草集",
					route_name: "GoodsArticle"
				},
				{
					title: "关于我们",
					route_name: "Intro"
				},
			]
		}
	},
	computed: {
		...mapGetters(['currentNav'])
	},
	created () {},
	mounted () {},
	methods: {
		jumpPage(e) {
			if(!e.currentTarget.dataset.page) {
				return
			}
			if(e.currentTarget.dataset.page === this.currentNav) {
				return
			}
			this.$router.push({name: e.currentTarget.dataset.page})
		}
	}
}
</script>

<style scoped>
.nav {
	height: 38px;
	background: linear-gradient(to left, #FF6C73, #FF5694);
}

.nav-list {
	display: flex;
	height: 100%;
}

.nav-item {
	height: 38px;
	line-height: 38px;
	font-size: 18px;
	color: #fff;
	padding: 0 20px;
	cursor: pointer;
	transition: all .5s;
}
.nav-item:hover {
	background: rgba(0,0,0,.1);
}
.nav-item-active {
	background: rgba(0,0,0,.1);
}
</style>
