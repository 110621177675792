<template>
	<div class="footer">
		<div class="wrapper">
			<div class="footer_top">
        <a href="https://beian.miit.gov.cn" target="_blank"
          >粤ICP备18020185号</a
        >
      </div>
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	data () {
		return {}
	},
	computed: {},
	created () {},
	mounted () {},
	methods: {}
}
</script>

<style scoped>
.footer {
	margin-top: 10px;
	height: 40px;
	background: #a0a0a0;
}

.footer_top {
	line-height: 40px;
	font-size: 15px;
  text-align: center;
	color: #FED730;
}
.footer_top a {
	color: #FED730;
}
</style>
