<template>
	<div class="header">
		<div class="wrapper">
			<div class="header-box flex">
				<div class="logo" @click="_gotoHome">
					<img src="@/assets/pc_images/logo.png" alt />
				</div>
				<!-- <div class="search">
					<input type="text" class="search-input" v-model.trim="keyword" placeholder="粘贴宝贝标题/链接">
					<div class="search-btn" @click="_search">搜索</div>
				</div> -->
				<div class="showphone">
					<el-tooltip placement="top-start" effect="light">
						<div slot="content">
							<el-image class="download-image" src="http://img.taojiefang.cn/FpoXw4JGmLK6II90NKx3TetClvgC"></el-image>
						</div>
						<img src="@/assets/pc_images/showphone.png" alt="">
					</el-tooltip>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { Tooltip , Image } from 'element-ui'

Vue.use(Tooltip).use(Image)

export default {
	components: {},
	data () {
		return {
			keyword: ''
		}
	},
	computed: {},
	created () {},
	mounted () {},
	methods: {
		_gotoHome() {
			this.$router.push({name: 'PcHome'})
		},

		_search() {
			if(!this.keyword) {
				return;
			}

			this.$router.replace({name: "GoodsList", query: {keyword: this.keyword}})
		}
	}
}
</script>

<style scoped>
.header {
	background: #fff;
}
.header-box {
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
}

.logo {
	width: 150px;
}
.logo img {
	width: 100%;
	cursor: pointer;
}

.search {
	margin-left:50px;
	width: 500px;
	display: flex;
	align-items: center;
	height: 34px;
	padding: 2px;
	background: linear-gradient(#FF6C73, #FF5694);
}
.search-input {
	font-size: 14px;
	width: 420px;
	border: none;
	border-right: 0;
	background: #fff;
	height: 30px;
	padding-left: 8px;
}
.search-btn {
	width: 80px;
	height: 32px;
	text-align: center;
	line-height: 32px;
	font-size: 16px;
	color: #fff;
	cursor: pointer;
}
.showphone {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	font-size: 0;
	cursor: pointer;
}
.showphone img {
	width: 160px;
}

.download-image {
	width: 120px;
}
</style>
